@import "global/base";

body {
  margin: 2rem;
}

h1 {
  @extend %headline1;

  text-transform: uppercase;
  line-height: 1.2;
}

h2 {
  @extend %headline2;

  text-align: right;
}

a {
  color: #37966f;
}

a:visited {
  color: $primary-color;
}

main {
  display: flex;
  flex-flow: column;
  align-items: center;
}

header h1 {
  margin: 0;
}

main img {
  width: 100%;
  max-width: 400px;
  clip-path: polygon(24% 3%, 98% 4%, 80% 79%, 98% 95%, 46% 100%, 3% 84%);
}

main h2 {
  z-index: 2;
  margin: 0;
}

main article {
  color: $primary-color;
}

article section {
  margin: 16px 0;
  padding: 16px;
  max-width: 540px;
  background-color: $surface-color;
}

@supports (display: grid) {
  body {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    height: 100vh;
    margin: 0 2rem;
  }

  main {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  @media (min-width: 1024px) {
    main {
      display: grid;
      grid-template-columns: 1fr minmax(100px, 400px) min-content;
      gap: 0 1rem;
    }

    main header {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }

    main img {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }

    main h2 {
      grid-column: 2 / 4;
      grid-row: 2 / 3;
      align-self: end;
    }

    main article {
      display: flex;
      flex-flow: wrap;
      grid-column: 1 / 2;
      grid-row: 2 / 4;
    }

    article section {
      margin: 16px;
      min-width: 560px;
    }
  }
}
